<template>
	<errorContainer :error="erreur" :warning="warning">
		<transition name="slide">
			<div
				class="container-layout details-document-package"
				:class="{
					'container-layout': $screen.width > 576,
					'container-mobile': $screen.width <= 576
				}"
			>
				<div class="form">
					<b-row>
						<b-col md="12">
							<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">
								{{ caption1 }}
							</h1>
						</b-col>
					</b-row>
					<b-row class="mt-3">
						<b-col class="p-0">
							<b-card no-body>
								<b-row class="back-with-title cursol-pointer py-1">
									<b-col cols="6">
										<button class="btn-transparent" @click="handleClickBack">
											<arrow-left color="rgba(6, 38, 62, 0.65)" :size="22" class="icon" />
											<span style="display: inline-block; color: #2e495d; font-weight: bolder; font-size: 16px">
												{{ FormMSG(163, 'Back') }}
											</span>
										</button>
									</b-col>
								</b-row>
								<b-card-text>
									<div class="container-field mt-3">
										<b-row>
											<b-col cols="6">
												<b-form-group :label="FormMSG(110, 'Total salaries for this function:')" label-for="salaries">
													<b-form-input
														:disabled="true"
														:value="this.displayCurrency(this.curBudgetDataDepCat.amountTotal)"
														type="text"
														id="salaries"
													></b-form-input>
												</b-form-group>
											</b-col>
										</b-row>
										<b-row class="mb-3">
											<b-col cols="6" v-if="$screen.width >= 992">
												<b-input-group v-if="$screen.width >= 992">
													<b-form-input
														v-model="filter"
														type="text"
														:placeholder="this.FormMSG(12, 'Type to Search')"
													/><!-- @change="handleChangeFilter" -->
													<b-input-group-append class="cursor-pointer">
														<b-input-group-text class="btn-search">
															<Search color="#FFFFFF" :size="16" class="icon" :stroke-width="2.5" v-if="filter.length === 0" />
															<X color="#FFFFFF" :size="16" class="icon" :stroke-width="2.5" @click="filter = ''" v-else />
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-col>
										</b-row>
										<!--     TABLE OF BUDGET PER DEPARTMENT     sort-by="departmentName"    use striped to highlight each even row  -->
										<b-row>
											<b-col cols="12" xl="12">
												<CardListBuilder custom-class="hide-on-desktop" :filter="filter" :fields="bgFields" :items="Users">
													<template slot="actions" slot-scope="data">
														<b-button variant="primary" size="sm" @click="rowClicked(data.item)">
															<i class="icon-pencil"></i>
														</b-button>
													</template>
												</CardListBuilder>
												<b-table
													class="hide-on-tablet"
													:hover="hover"
													responsive="sm"
													sticky-header="700px"
													:filter="filter"
													:items="Users"
													style="text-align: left"
													:fields="bgFields"
													:current-page="currentPage"
													:per-page="perPage"
													@row-clicked="rowClicked"
													:head-variant="hv"
													bordered
													small
													show-empty
													:empty-text="FormMSG(265, 'No salaries found')"
												>
												</b-table>
											</b-col>
										</b-row>
										<loading :active.sync="isLoading" :is-full-page="true"></loading>
									</div>
								</b-card-text>
							</b-card>
						</b-col>
					</b-row>
				</div>
			</div>
		</transition>
	</errorContainer>
</template>

<script>
import gql from 'graphql-tag';
import { store } from '@/store';

import Loading from 'vue-loading-overlay';
import { isNil } from '@/shared/utils';

import languageMessages from '@/mixins/languageMessages';
import { rendCurrency, rendKgCo2 } from '~helpers';
import { Search, X, ArrowLeft } from 'lucide-vue';

const query_budget_global_salary_one_dep = gql`
	query ($DepartmentNumber: Int, $FunctionNumber: Int!) {
		GetBudgetDataDetailSalaryList(DepartmentNumber: $DepartmentNumber, FunctionNumber: $FunctionNumber) {
			id
			totalSalary
			totalKgCoTwo
			totalSalaryBudget
			name
			firstName
			functionName
			email
		}
	}
`;

export default {
	name: 'TotalSalariesForOneDepartment',
	mixins: [languageMessages],
	props: {
		hover: {
			type: Boolean,
			default: true
		},
		striped: {
			type: Boolean,
			default: true
		},
		bordered: {
			type: Boolean,
			default: true
		},
		small: {
			type: Boolean,
			default: false
		},
		fixed: {
			type: Boolean,
			default: false
		}
	},
	components: {
		Loading,
		Search,
		X,
		ArrowLeft
	},
	created() {
		this.reloadData();
	},
	data: () => {
		return {
			hv: 'dark',
			Validated: 0,
			erreur: {},
			filter: '',
			Users: [],
			currentPage: 1,
			perPage: 0,
			warning: '',
			successModal: false,
			isLoading: false,
			curBudgetDataDepCat: {}
		};
	},
	computed: {
		bgFields() {
			return [
				{
					key: 'id',
					label: this.FormMSG(20, 'Id'),
					sortable: true
				},
				{
					key: 'name',
					label: this.FormMSG(21, 'Name'),
					sortable: true
				},
				{
					key: 'firstName',
					label: this.FormMSG(22, 'Firstname'),
					sortable: true
				},
				{
					key: 'functionName',
					label: this.FormMSG(23, 'Function'),
					sortable: true
				},
				{
					key: 'totalSalary',
					label: this.FormMSG(24, 'Total salary'),
					formatter: (value) => {
						return rendCurrency(value);
					},
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'totalKgCoTwo',
					label: 'CO2',
					formatter: (value) => {
						return rendKgCo2(value);
					},
					class: 'text-right',
					sortable: true
				},
				{
					key: 'totalSalaryBudget',
					label: this.FormMSG(25, 'Initial budget'),
					formatter: (value) => {
						return rendCurrency(value);
					},
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'amountLeft',
					label: this.FormMSG(26, 'Amount left'),
					formatter: (value) => {
						return rendCurrency(value);
					},
					sortable: true,
					class: 'text-right'
				}
			];
		},
		caption1: function () {
			return this.FormMSG(1, 'Salaries for department:') + ' ' + this.curBudgetDataDepCat.departmentName + ' / ' + this.curBudgetDataDepCat.name;
		},
		styleObject: function () {
			var myObj = {
				color: 'black',
				fontSize: '16px'
			};
			if (this.curBudgetDataDepCat.amountLeft >= 0) {
				myObj.color = 'green';
			} else {
				myObj.color = 'red';
			}
			return myObj;
		}
	},
	methods: {
		handleClickBack() {
			if (this.$route.query.budgetPerCategory) {
				this.$router.push(`/budget?activeTabPerCategory=1&search=${this.$route.query.search}`);
			} else {
				this.$router.go(-1);
			}
		},
		displayCurrency(value) {
			return rendCurrency(value);
		},
		updateSalaryForEach(BudgetData) {
			// setting row color
			// https://bootstrap-vue.js.org/docs/reference/color-variants
			BudgetData.amountLeft = BudgetData.totalSalaryBudget - BudgetData.totalSalary;
			if (BudgetData.amountLeft < 0) {
				//BudgetData._rowVariant = 'danger';
				BudgetData._cellVariants = {
					amountLeft: 'danger'
				};
			} else {
				BudgetData._cellVariants = {
					amountLeft: 'success'
				};
				//BudgetData._rowVariant = 'success';
			}
		},
		reloadData() {
			this.isLoading = true;
			//console.log("in reloadData");
			this.curBudgetDataDepCat = store.getCurBudgetDataForDepartmentCategory();
			// console.log("curBudgetDataDepCat:",this.curBudgetDataDepCat);
			//var DepartmentNumber = this.curBudgetDataDepCat.department;
			var FunctionNumber = parseInt(this.curBudgetDataDepCat.category, 10);
			this.$apollo
				.query({
					query: query_budget_global_salary_one_dep,
					variables: {
						FunctionNumber
					},
					// options: { fetchPolicy: 'network-only' },
					// options: { fetchPolicy: 'no-cache' },
					// force update from server
					fetchPolicy: 'network-only'
				})
				.then((result) => {
					//console.log("reloaddata:", result.data);
					result.data.GetBudgetDataDetailSalaryList.forEach(this.updateSalaryForEach);
					this.Users = result.data.GetBudgetDataDetailSalaryList;
					//console.log("reloaddata:", this.Users);
				})
				.catch((error) => {
					//console.log(error)
					this.erreur = error;
				})
				.finally(() => (this.isLoading = false));
		},
		getRowCount(items) {
			//console.log(items)
			return items.length;
		},
		salaryDetailLink(item) {
			return `/budget/department/${this.curBudgetDataDepCat.department.toString()}/salaries/${item.id.toString()}`;
		},
		rowClicked(item) {
			//console.log("in row click");
			const salaryDetailLink = this.salaryDetailLink(item);
			//console.log("salaryDetailLink",salaryDetailLink);
			this.$router.push({
				path: salaryDetailLink
			});
		}
	}
};
</script>
